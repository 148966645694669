import style from './action.module.css';
import {Button} from "components/shared/button";
import {CircularProgress, Divider, Typography} from "@mui/material";
import {useCookies} from "react-cookie";
import {useStore} from "../../../hooks/use-store";
import {observer} from "mobx-react-lite";
import {useRouter} from "next/router";
import {useUser} from "../../../hooks/use-user";

export const Action = observer(() => {
    const {isLoaded, isAuthenticated} = useUser();
    const {push} = useRouter();

    const handleClick = () => {
        push("/api/saml")
    }

    if (!isLoaded || isAuthenticated) {
        return <div className={style.root}>
            <CircularProgress size={24}/>
            <Typography variant={'caption'}>Authenticating...</Typography>
        </div>
    }

    return <div className={style.root}>
        <Button label={"Sign in with SSO"} onClick={handleClick} />
    </div>
});
