import style from "./index.module.css";
import { Divider, Typography } from "@mui/material";
import { Inputs } from "components/login/box/inputs";
import { Action } from "components/login/box/action";
import { APPLICATION_NAME } from "src/utils/create-page-title";
import { useSearchParams } from "next/navigation";

export const Box = () => {
  const search = useSearchParams();

  const error = search.get("error");

  return (
    <div className={style.root}>
      <div className={style.frame}>
        <Typography color={"primary"} className={style.title} variant={"h1"}>
          {APPLICATION_NAME}
        </Typography>
        <div className={style.container}>
          <Typography variant={"h4"} color={"primary"}>
            Login with your account
          </Typography>
          <Action />
          <Divider style={{ width: "100%" }} />
          <Typography variant={"caption"}>
            To edit the Evidence Tool Dashboard, please <a>request access</a>.
          </Typography>
          {error && (
            <p
              className={
                "text-red-700 text-xs font-semibold bg-red-50 rounded p-2"
              }
            >
              Login Error: {error}
            </p>
          )}
        </div>
        <div className={style.leftLine} />
        <div className={style.topLine} />
        <div className={style.topNotch} />
        <div className={style.rightLine} />
        <div className={style.bottomLine} />
        <div className={style.bottomNotch} />
      </div>
    </div>
  );
};
