import style from "./index.module.css";

export const Logo = () => {
  return (
    <div className={style.root}>
      <img
        src={"/images/bluepath-logo.png"}
        width={170}
        alt={"Bluepath logo"}
      />
    </div>
  );
};
