import useSWR from "swr";
import { useMemo } from "react";
import { fetcher } from "../utils/fetcher";
import { Permission } from "./use-legacy-permission";
import { Tool } from "types/tool";
import { useRouter } from "next/router";

type User = {
  email: string;
  name: string;
  role: Permission;
  division: string;
  tools: Tool[];
};

type UseUserOptions = {
  redirect?: boolean;
};

export const useUser = ({ redirect }: UseUserOptions = {}): {
  user?: User;
  isAuthenticated: boolean;
  isLoaded: boolean;
} => {
  const { data, error } = useSWR(`/api/user`, fetcher, {
    revalidateOnMount: true,
  });

  const { replace } = useRouter();

  const { user, isAuthenticated, isLoaded } = useMemo(() => {
    if (error || data?.error) return { isAuthenticated: false, isLoaded: true };
    if (!data) return { isAuthenticated: false, isLoaded: false };
    const { user } = data;

    const isAuthenticated = !!user && !!user.tools.length;

    if (user.authVersion !== 2) {
      replace("/logout");
    }

    return {
      user,
      isAuthenticated,
      isLoaded: true,
    };
  }, [data, error, redirect]);

  if (isLoaded && !isAuthenticated && redirect) {
    replace(
      "/login?error='Your account does not have access to the evidence tool'"
    );
  }

  return { user, isAuthenticated, isLoaded };
};
