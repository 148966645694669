import styles from "../../styles/Login.module.css";
import Head from "next/head";
import { Logo } from "components/login/logo";
import { Footer } from "components/login/footer";
import { Box } from "components/login/box";
import { createPageTitle } from "src/utils/create-page-title";
import { useUser } from "../../src/hooks/use-user";
import { useRouter } from "next/router";
import { useEffect } from "react";
import cookie from "cookie";

const Login = () => {
  const { replace } = useRouter();
  const { isAuthenticated, isLoaded } = useUser();

  useEffect(() => {
    if (isLoaded && isAuthenticated) {
      replace("/welcome");
    }
  }, [isLoaded, isAuthenticated, replace]);

  return (
    <div className={styles.root}>
      <Head>
        <title>{createPageTitle("Login")}</title>
      </Head>
      <div className={styles.frame}>
        <Logo />
        <Box />
        <Footer />
      </div>
    </div>
  );
};

export async function getServerSideProps({ req, res }) {
  const fallback = {};

  const cookies = cookie.parse(req.headers.cookie ?? "");

  if (!cookies["connect.sid"]) {
    fallback["/api/user"] = { error: "No cookie" };
  }

  return {
    props: { fallback },
  };
}
export default Login;
