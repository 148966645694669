import style from './index.module.css';
import {ReactNode} from "react";

type ButtonProps = {
    color?: 'primary' | 'secondary';
    onClick?: () => void;
    label: string;
    disabled?: boolean;
    icon?: ReactNode;
    ['data-step']?: string;
}

export const Button = ({color = 'primary', label, onClick, disabled, icon, ...other}: ButtonProps) => {
    return <div className={`${style.button} ${disabled ? style.disabled : ''} ${style[color]}`} onClick={onClick} {...other}>
        {icon}
        {label}
    </div>
}
